import React, { Component } from "react";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/footer";
import Group from "../assets/Group.png";
import Call from "../assets/Path.svg";
import Mobile from "../assets/mobility.svg";
import trukko from "../assets/trukko.svg";

import Application from "../assets/Application.svg";
import Cloud from "../assets/Cloud.svg";
import Product from "../assets/product.svg";
import Internet from "../assets/internet.svg";
import Teliver from "../assets/teliver.svg";
import Arrow from "../assets/arrow.svg";
import Shoppy from "../assets/shoppy.svg";
import Bindr from "../assets/bindr.svg";
import parknstays from "../assets/parknstays.svg";

import Mealbuzz from "../assets/mealbuzz.svg";
import Ippopay from "../assets/ippopay.svg";
import GotennisLogo from "../assets/gotennisLogo.svg";
import Elvalto from "../assets/elvalto.svg";
import FaceOne from "../assets/faceOne.jpg";
import FaceTwo from "../assets/facetwo.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import QKart from "../../src/assets/Qkart.png";
import AOS from "aos";
import "aos/dist/aos.css";
import scrollToTop from "../utils/utils";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }
  componentDidMount() {
    scrollToTop();
    AOS.init({
      disable: function () {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
        // return true;
      },
    });
  }
  render() {
    return (
      <>
        <Navbar props={this.props} />
        <div className="Home__banner">
          <div className="banner-content">
            <h3 data-aos="fade-right" data-aos-duration="1000">
              Digitalization Simplified For You
            </h3>
            <p
              className="remedy"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              Holistically designed software solutions for your complex
              technical challenges within your establishments.
            </p>

            <div
              className="parent"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1100"
            >
              <div className="div1">
                <p>50+</p>
                <p>Happy Clients</p>
              </div>
              <div className="div2">
                <p>100+</p>
                <p>Project Completed</p>
              </div>
              <div className="div3 ">
                <p className="letter_space">99.99%</p>
                <p>Client Statisfication</p>
              </div>
            </div>
          </div>
          <div>
            <img
              className="group"
              src={Group}
              data-aos="zoom-in-left"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <div className="Home__services">
          <h2>
            Our <p> Services</p>
          </h2>
          <p className="content">
            Robust solutions to fit your needs with elite assistance by our team
            <br></br>
            throughout the software cycle be it Mobile, Web-based or Cloud
            domains.
          </p>
          <div className="parent">
            <div className="div1" data-aos="zoom-in" data-aos-duration="1000">
              {" "}
              <img src={Mobile} />
              <h3>Mobility</h3>
              <p className="common_para">
                Solidly build on the go mobile apps both for Android and iOS
                platforms with brilliant user interface and multiple security
                layers.
              </p>
            </div>
            <div
              className="div2"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              {" "}
              <img src={Application} />
              <h3>Web Apps</h3>
              <p className="common_para">
                A sophisticated display of your establishment <br />
                to the world's eye.
              </p>{" "}
            </div>
            <div
              className="div3"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              {" "}
              <img src={Cloud} />
              <h3>Cloud Services</h3>
              <p className="common_para">
                On demand availability of computing services for a large number
                of users from any remote server.
              </p>
            </div>
            <div
              className="div4"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              {" "}
              <img src={Product} />
              <h3>Product Consulting</h3>
              <p className="common_para">
                Guidance and end to end support through the process of
                designing, developing or customizing products by our experienced
                professionals with solid experience.
              </p>
            </div>
            <div
              className="div5"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="700"
            >
              {" "}
              <img src={Internet} />
              <h3>Internet of things</h3>
              <p className="common_para">
                Always ahead when it comes to technology and our IoT team has
                mastered it to provide the best ever IoT solutions.
              </p>
            </div>
          </div>
        </div>
        <div className="Home__solutions">
          <h2>
            Our <span>Instant Solutions</span>
          </h2>
          <Carousel
            showIndicators={true}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={6100}
          >
            <div className="solutions_wrap">
              <div className="wrap-child">
                <p
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  There is not always a need to develop something from scratch
                  when we can use our highly customizable, ready available
                  solutions that can adapt to all your needs.
                </p>
                <h2
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Real-time tracking solutions ‘T’ELIVERED
                </h2>
                <p
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  A coded marvel in the form of SDK for business or personal
                  apps which requires live tracking.
                </p>
                <p
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  A simple embed and your app is all set to revolutionize the
                  user experience with its limitless options and accurate
                  tracking capabilities.
                </p>
                <a
                  className="route_link"
                  href="https://www.teliver.io/"
                  target="_blank"
                >
                  <button>
                    View More <img src={Arrow} />
                  </button>
                </a>
              </div>
              <div>
                <img
                  src={Teliver}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                />
              </div>
            </div>
            <div className="solutions_wrap">
              {" "}
              <div className="wrap-child">
                <p
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  Working to earn and keep our customers’ trust is the single
                  biggest driver of Qkart's approach.
                </p>
                <h2
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  E-Commerce Solution <span>Qkart</span>
                </h2>
                <p
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  Qkart is developed to engage and develop your e-commerce
                  ideas. We provides you the opportunity to purchase our product
                  and implement whats on your mind.
                </p>
                <p
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  Qkart product is built as a solution to the new innovations of
                  e-commerce with greater, faster and more reliable resource for
                  your new creations.
                </p>
                {/* <button>
                  View More <img src={Arrow} />
                </button> */}
              </div>
              <div>
                <img
                  src={QKart}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </Carousel>
        </div>
        <div className="Home__works">
          <h2>
            Our <span>Works</span>
          </h2>
          <p className="content">
            It's not what we aspire to be but what we achieve that defines us.{" "}
            <br></br> A sneak peek into what we have achieved so far.
          </p>
          <div className="slider">
            <div className="slide-track">
              {/* <div className="slide">
                <img src={Mealbuzz} height="100" width="250" alt="" />
              </div> */}
              {/* <div className="slide">
                <img src={Shoppy} height="100" width="250" alt="" />
              </div> */}
              <div className="slide">
                <a href="https://bindr.ai/" target="_blank">
                  <img
                    src={Bindr}
                    height="100"
                    style={{ width: "70%" }}
                    alt=""
                  />
                </a>
              </div>
              <div className="slide">
                <a href="https://parknstays.com/" target="_blank">
                  <img
                    className="mt_15"
                    src={parknstays}
                    height="100"
                    style={{ width: "80%" }}
                    alt=""
                  />
                </a>
              </div>{" "}
              <div className="slide">
                <a href="https://trukko.co/" target="_blank">
                  <img
                    src={trukko}
                    height="100"
                    style={{ width: "70%" }}
                    alt=""
                  />
                </a>
              </div>{" "}
              {/* <div className="slide">
                <img src={GotennisLogo} height="100" width="250" alt="" />
              </div> */}
              {/* <div className="slide">
                <img src={Shoppy} height="100" width="250" alt="" />
              </div> */}
              {/* <div className="slide">
                <img src={Mealbuzz} height="100" width="250" alt="" />
              </div> */}
              {/* <div className="slide">
                <img src={GotennisLogo} height="100" width="250" alt="" />
              </div>{" "} */}
              {/* <div className="slide">
                <img src={Bindr} style={{ width: "70%" }} height="100" alt="" />
              </div> */}
              {/* <div className="slide">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
                  height="100"
                  width="250"
                  alt=""
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="Home__clients">
          <h2>
            Clients <span>Testimonials </span>
          </h2>
          <p className="content">
            Enough of us telling you how good we are !. Listen to some of the
            shout outs from our clients.
          </p>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            // autoPlay={true}
            // interval={2000}
          >
            <div>
              <div className="white-caro">
                <div className="myCarousel">
                  {/* <img src={FaceOne} /> */}
                  <h3>Sakthi </h3>
                  <h4>CEO of Bindr.AI.</h4>
                  <p>
                    Strong customer focus with a stellar technical background is
                    what makes Quadkast unique. They continue to be our reliable
                    partner within the Bindr.AI ecosystem. If you look to
                    improvise design or develop, don't look elsewhere.
                  </p>
                  <div className="invisible-right"></div>
                  <div className="invisible-left"></div>
                </div>
              </div>
            </div>
            <div>
              <div className="white-caro">
                <div className="myCarousel">
                  {/* <img src={FaceOne} /> */}
                  <h3>Navaneeth Mattummal </h3>
                  <h4>Founder & CEO of Trukko</h4>
                  <p>
                    We at Trukko couldn't be happier with our experience working
                    with QuadKast. They were instrumental in developing our
                    entire platform. We highly recommend QuadKast to any company
                    seeking a reliable and talented development partner. Their
                    strong technical skills, coupled with their commitment to
                    open communication and collaboration, make them a pleasure
                    to work with.
                  </p>
                  <div className="invisible-right"></div>
                  <div className="invisible-left"></div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
        <Footer props={this.props} />
      </>
    );
  }
}
export default Home;
