import React, { Component } from "react";
import Navbar from "../layouts/Navbar";
import Banner from "../assets/how_we_do/howBanner.svg";
import Rafiki from "../assets/how_we_do/rafiki.svg";
import Board from "../assets/how_we_do/board.svg";
import Pana from "../assets/how_we_do/pana.svg";
import Amico from "../assets/how_we_do/amico.svg";
import Man from "../assets/how_we_do/man.svg";
import Rocket from "../assets/how_we_do/rocket.svg";
import Bro from "../assets/how_we_do/bro.svg";
import Success from "../assets/how_we_do/success.svg";
import SuccessTwo from "../assets/how_we_do/success2.svg";
import Footer from "../layouts/footer";
import Call from "../assets/Path.svg";
import scrollToTop from "../utils/utils";
import AOS from "aos";
import "aos/dist/aos.css";
class HowWeDo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    scrollToTop();
    AOS.init({
      disable: function () {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
        // return true;
      },
    });
  }
  render() {
    return (
      <>
        <Navbar props={this.props} />
        <div className="How__banner">
          <div className="banner-content">
            <p
              className="remedy"
              data-aos="fade-down-right"
              data-aos-duration="1000"
            >
              How we work Our work flow and tools
            </p>
            <p
              className="remedy_content"
              data-aos="fade-up-right"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              Our process always focuses on on-time delivery of quality software
              to the utmost satisfaction of our customer which has passed rounds
              of complex testing. To assist us through this process we utilize
              different tools and workflow to help track our progress.
            </p>
            {/* <div
              className="call-trigger"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <p>Let’s work together</p>
            </div> */}
          </div>
          <div>
            <img
              src={Banner}
              data-aos="fade-down-left"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <div className="How__process">
          <h2>
            Our <p> Process</p>
          </h2>
          <div className="process-content">
            <div className="banner-content">
              <p
                className="remedy"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                1.Initial Discussion
              </p>
              <p
                className="remedy_content"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                During the first contact, we want to learn your business goals
                and product vision and collect the information on the most
                important functionalities. We also answer the first questions
                and inform you about the predicted project cost. We review the
                materials you provide and form a team that will conduct the
                project analysis.
              </p>
            </div>
            <div className="img-wrap">
              {" "}
              <img src={Rafiki} data-aos="fade-left" data-aos-duration="1000" />
            </div>
          </div>
          <div className="process-content">
            <div className="img-wrap">
              {" "}
              <img src={Board} data-aos="fade-right" data-aos-duration="1000" />
            </div>

            <div className="banner-content">
              <p
                className="remedy"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                2.Scope Conformation
              </p>
              <p
                className="remedy_content"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                During this stage we would like to establish what the plans were
                made on the initial discussion and bring together structure to
                the project so that both we and the client can accept. This
                includes all the technical functionalities and screen or page by
                page expectations and a technical document is created so that we
                can agree upon and start our initial work.
              </p>
            </div>
          </div>
          <div className="process-content">
            <div className="banner-content">
              <p
                className="remedy"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                3.Kick Start
              </p>
              <p
                className="remedy_content"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                This is where the work start toward achieving the set goal and
                will periodically allowed to be reviewed so as to confirm the
                project is steered in the right direction. From here the project
                starts to get a structure as our team will be involved in
                developing and testing so as to meet the target deadlines. Here
                customers are highly appreciated to intervene so as to reassure
                that we are headed in the right direction.
              </p>
            </div>
            <div>
              <div className="img-wrap">
                <img
                  src={Amico}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
          <div className="process-content">
            <div className="img-wrap">
              <img src={Pana} data-aos="fade-right" data-aos-duration="1000" />
            </div>
            <div className="banner-content">
              <p
                className="remedy"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                4.Development
              </p>
              <p
                className="remedy_content"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                Multiple teams work on different aspects of the project
                development and serious testing will be undergoing, both from
                the technical as well as the functional streams. During this
                stage we would be releasing the software for the customer to be
                tested as well so as to take feedback and incorporate them to
                already done or the upcoming tasks.
              </p>
            </div>
          </div>
          <div className="process-content">
            <div className="banner-content">
              <p
                className="remedy"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                5.UAT and Testing
              </p>
              <p
                className="remedy_content"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                At this stage we would create the UAT server and deploy the
                built solution into it for the customer to be tested and
                simultaneously out technical testing team would be doing
                rigorous load tests, functionality and technical glit testing
                just to eliminate any issues prevailing in the system. This
                process continues until the customer agrees and certifies with
                confidence that the application is ready to be moved to the next
                stage.
              </p>
            </div>
            <div className="img-wrap">
              {" "}
              <img src={Man} data-aos="fade-left" data-aos-duration="1000" />
            </div>
          </div>
          <div className="process-content">
            <div className="img-wrap">
              <img
                src={Rocket}
                data-aos="fade-right"
                data-aos-duration="1000"
              />
            </div>
            <div className="banner-content">
              <p
                className="remedy"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                6.Release
              </p>
              <p
                className="remedy_content"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                The final setup of the server and all other allocations would be
                completed and the project is deployed finally for customer use.
              </p>
            </div>
          </div>
          <div className="process-content">
            <div className="banner-content">
              <p
                className="remedy"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                7.Project Support
              </p>
              <p
                className="remedy_content"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                Support throught the project is our valuable selling point. As
                we assure to offer support at any level and adhere to fix bugs
                or include or modify functionality and ensure smooth transition
                and give very smooth customer experience.
              </p>
            </div>
            <div className="img-wrap">
              <img src={Bro} data-aos="fade-left" data-aos-duration="1000" />
            </div>
          </div>
        </div>
        <div className="How__success">
          <h2>
            How We Achieve <p> Success</p>
          </h2>
          <div className="flex">
            <div className="flex-content-initial">
              <div className="vertical_sphere">
                <div className="vertical_sphere_inherit"></div>
              </div>
            </div>
            <div className="flex-content">
              <div className="flex-wrap-content">
                <p>
                  OPEN AND HONEST COMMUNICATION RESULTS IN SUCCESSFUL PROJECTS
                </p>
                <div className="hori-line"></div>
                <p>
                  Ask those who have failed at software outsourcing. The failure
                  can likely be attributed to poor communication. A thorough
                  initial evaluation of your potential partner and comprehensive
                  project setup will help everyone achieve success in the
                  outsourcing partnership. Past challenges have helped us create
                  a successful system that enables us circumvent communication
                  issues.
                </p>
                <p>
                  As a true extension of your team, Orient Software strives to
                  facilitate clear visibility to you. To that end, Orient has
                  designed highly effective communication processes,
                  configuration management and issue tracking systems. Our
                  meeting rooms are equipped with the most modern communication
                  facilities and infrastructure that include: Cisco
                  communications, Microsoft Lync, TeamViewer and video Skype.
                </p>
                <div>
                  <img src={Success} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex-content-initial">
              <div className="vertical_sphere">
                <div className="vertical_sphere_inherit"></div>
              </div>
            </div>
            <div className="flex-content">
              <div className="flex-wrap-content">
                <p>STRONG PROJECT MANAGEMENT</p>
                <div className="hori-line"></div>
                <p>
                  To solve any project related issue that may arise in the
                  process, a project manager is appointed from Day 1 to work
                  directly with the client. Project Manager responsibilities
                  include initial project planning, team management, early
                  project risks definition / elimination and act as an
                  escalation point for the client. All projects are ultimately
                  coordinated under the Quadkast Technologies
                </p>
                <p>
                  As a true extension of your team, Orient Software strives to
                  facilitate clear visibility to you. To that end, Orient has
                  designed highly effective communication processes,
                  configuration management and issue tracking systems. Our
                  meeting rooms are equipped with the most modern communication
                  facilities and infrastructure that include: Cisco
                  communications, Microsoft Lync, TeamViewer and video Skype.
                </p>
                <div>
                  <img src={SuccessTwo} />
                </div>
              </div>
            </div>
          </div>
          <div className="vl"></div>
        </div>
        <Footer props={this.props} />
      </>
    );
  }
}
export default HowWeDo;
