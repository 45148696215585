import React, { Component } from "react";
import Navbar from "../layouts/Navbar";
import CareersImg from "../assets/careers/careers1.svg";
import Box from "../assets/careers/box.svg";
import Arrow from "../assets/careers/arrow.svg";
import Badge from "../assets/careers/badge.svg";
import People from "../assets/careers/people.svg";
import Weight from "../assets/careers/weight.svg";
import Diamond from "../assets/careers/diamond.svg";
import Footer from "../layouts/footer";
import scrollToTop from "../utils/utils";
import Cross from "../assets/careers/cross.svg";
import ResumeIcon from "../assets/careers/resume.svg";
import Modal from "react-modal";
import {
  isInvalidName,
  isInvalidEmail,
  GET_AWS_LINK,
  isInvalidImage,
  isLargeFile,
} from "../constants/utils";
import ImageUploader from "../Component/ImageUploader";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import APIGateway from "../constants/ApiCall";
import ApiCall from "../constants/ApiCall";
import { handleBreakpoints } from "@mui/system";
// import "bootstrap/dist/css/bootstrap.min.css";
class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      name: "",
      email: "",
      optionsType: "",
      dummy: "MyPersonalResume",
      resume: null,
      errors: {
        nameErr: false,
        emailErr: false,
        resumeErr: false,
        inValidPdf: false,
        isLargeFile: false,
      },
      fileObj: "",
      mrpImg: "",
      getJobRoles: [],
      jobTitle: "",
      fileName: "",
      isMrpImgLoading: false,
    };
  }
  componentDidMount = async () => {
    scrollToTop();
    AOS.init({
      disable: function () {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
        // return true;
      },
    });
    await this.getJobList();
  };
  getJobList = () => {
    APIGateway.get("auth/jobs", (response) => {
      if (response.success) {
        let { jobs } = response.data;
        this.setState({
          getJobRoles: jobs,
        });
      }
    });
  };

  handleModal = (jobTitle) => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      optionsType: jobTitle,
      errors: {
        nameErr: false,
        emailErr: false,
        resumeErr: false,
        inValidPdf: false,
        isLargeFile: false,
      },
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  // handleSelectChange = (event) => {
  //   this.setState({ value: event.target.value });
  // };
  handleSubmit = () => {
    let { name, email, optionsType, resume, fileObj } = this.state;
    var self = this;
    if (name.trim() === "" || isInvalidName(name.trim())) {
      this.setState({
        errors: {
          nameErr: true,
          emailErr: false,
          resumeErr: false,
        },
      });
    } else if (email === "" || isInvalidEmail(email)) {
      this.setState({
        errors: {
          nameErr: false,
          emailErr: true,
          resumeErr: false,
        },
      });
    } else if (this.state.mrpImg === "") {
      this.setState({
        errors: {
          nameErr: false,
          emailErr: false,
          resumeErr: true,
        },
      });
    } else {
      this.setState({
        errors: {
          nameErr: false,
          emailErr: false,
          resumeErr: false,
        },
      });
      let data = {
        name: name,
        email: email,
        role_name: optionsType,
        document: this.state.mrpImg,
      };
      ApiCall.post("auth/careers", data, (res) => {
        if (res.success) {
          this.setState({
            name: "",
            email: "",
            optionsType: "",
            mrpImg: "",
            modalOpen: false,
          });
        } else {
        }
      });
    }
  };
  //Image Upload Handler
  onStart = (file, next, name, loader) => {
    var self = this;
    self.setState({
      fileName: file.name.split(".")[0],
      isMrpImgLoading: true,
    });
    if (isInvalidImage(file))
      this.setState({
        errors: {
          inValidPdf: true,
        },
      });
    else if (isLargeFile(file))
      this.setState({
        errors: {
          inValidPdf: false,
          isLargeFile: true,
        },
      });
    else {
      this.setState(
        {
          [loader]: true,
          inValidPdf: false,
          isLargeFile: false,
        },
        () => this.startUploading(file, next)
      );
    }
  };

  onError = (message, name, loader) => {
    this.onImageUploadFailure(message, name, loader);
  };

  onFinish = (url, name, loader) => {
    this.finishUploading(url, name, loader);
  };

  startUploading = (file, next) => {
    var reader = new FileReader();
    reader.onload = function (e) {};
    reader.readAsDataURL(file);
    next(file);
  };

  finishUploading = (url, name, loader) => {
    this.setState({
      [name]: GET_AWS_LINK(url),
      [loader]: false,
      isMrpImgLoading: false,
      errors: { resumeErr: false },
      // resume: url.filename,
    });
  };

  onImageUploadFailure(message, name, loader) {
    var self = this;
    this.setState({
      [name]: "",
      [loader]: false,
    });
  }
  render() {
    let {
      modalOpen,
      name,
      email,
      optionsType,
      errors,
      resume,
      mrpImg,
      isMrpImgLoading,
      getJobRoles,
      fileName,
      jobTitle,
    } = this.state;
    return (
      <>
        <Navbar props={this.props} />
        <div className="Careers__banner">
          <div
            className="banner-content"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p className="remedy">
              Join Our <span> Team </span>{" "}
            </p>
            <p className="remedy_content">
              We are an experienced, forward-thinking team of software engineers
              shaping a new reality with exciting ideas and cutting-edge
              technology. Join us and get new opportunities for career growth.
            </p>
          </div>
          <div>
            <img
              src={CareersImg}
              data-aos="flip-down"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <div
          className="Careers__work"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h2>
            Why Should You Work
            <p> With Us?</p>
          </h2>
          <p className="work_extend">
            Quadkast is not just a place to work. It is the place to learn, grow
            and succeed together. When you collaborate with us, you are sure to
            get a plethora of opportunities along with memories that you will
            cherish forever.
          </p>
          <div className="cards">
            <div
              className="cards__item"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <img src={Box} />
              <p>Flexible Schedule</p>
              <p>
                We encourage a flexible work schedule to enable employees to
                produce better results and get job satisfaction while mitigating
                stress.
              </p>
            </div>
            <div
              className="cards__item"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <img src={Diamond} />
              <p>Clear and Defined Purpose</p>
              <p>
                At Quadkast, we have a clear and defined purpose that allows
                employees to have a goal in mind.
              </p>
            </div>
            <div
              className="cards__item"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img src={People} />
              <p>Good Communication</p>
              <p>
                We ensure effective communication between employees and the
                management with frequent feedback
              </p>
            </div>
          </div>
          <div className="cards">
            <div
              className="cards__item"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <img src={Arrow} />
              <p>Growth Opportunities</p>
              <p>
                Quadkast is a world-class company that offers several growth
                opportunities for employees to improve their skills and reach
                new heights.
              </p>
            </div>
            <div
              className="cards__item"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <img src={Weight} />
              <p>Strong Core Value</p>
              <p>
                Our core values are much more than vision statements. We have a
                positive, purpose-driven culture that helps uplift employee
                morale.
              </p>
            </div>
            <div
              className="cards__item"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              <img src={Badge} />
              <p>Reward Systems</p>
              <p>
                To encourage employee engagement, we have introduced reward
                systems for employees who show exceptional performance .
              </p>
            </div>
          </div>
        </div>
        <div
          className="Careers__opportunity"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h2>
            Current
            <p> Opportunities?</p>
          </h2>
          <p className="work_extend">
            At Quadkast, we believe everyone is capable of greatness. So we’ve
            created a positive, supportive workplace where each team member can
            grow and thrive. When you join the Quadkast team, here’s what you’ll
            discover:
          </p>
          <div className="opportunities">
            {getJobRoles.map(
              (role, i) =>
                role.name !== "Co-Founder" && (
                  <div className="opportunities_inherit" key={i}>
                    <div className="content-wrapper">
                      <p>{role.title}</p>
                      <p>{role.skills}</p>
                    </div>
                    <div
                      className="call-trigger"
                      onClick={() => this.handleModal(role.title)}
                    >
                      <p>Apply Now</p>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          className="career_div_modal"
        >
          <div className="cross">
            <img onClick={() => this.handleModal()} src={Cross} />
          </div>
          <div className="content">
            <div className="content-form">
              <label>Name</label>
              <input
                placeholder="Name *"
                type="text"
                value={name}
                name="name"
                onChange={this.handleChange}
              />
            </div>
            <div className="span-content">
              <span className={errors.nameErr ? "errorMsg" : "hideMsg"}>
                Please enter valid name
              </span>
            </div>
            <div className="content-form">
              <label>Email</label>
              <input
                placeholder="Email *"
                type="email"
                value={email}
                name="email"
                onChange={this.handleChange}
              />
            </div>
            <div className="span-content">
              <span className={errors.emailErr ? "errorMsg" : "hideMsg"}>
                Please enter valid email
              </span>
            </div>
            <div className="content-form">
              <label className="mb_20 mt_20">Role:</label>
              <p> {optionsType && optionsType}</p>
            </div>
            <div className="content-form">
              <ImageUploader
                label="MRP Image"
                imageName={`${fileName}_resume_upload_${moment(
                  new Date()
                ).format("DD_MM_YY_h_mm_ss_a")}`}
                // path={`merchant/store/category`}
                name="mrpImg"
                value={mrpImg}
                currentFileName={fileName}
                loader="isMrpImgLoading"
                isLoading={isMrpImgLoading}
                start={this.onStart}
                error={this.onError}
                finish={this.onFinish}
                style={{ marginBottom: "2rem" }}
                remove={() => this.setState({ mrpImg: "" })}
              />
              <label
                htmlFor="uploadResume"
                className={errors.resumeErr ? "border-red" : "upload-doc"}
              >
                {mrpImg !== "" ? (
                  <div className="resume-wrap-up">
                    {/* <p>{resume}</p> */}
                    <p>Resume Uploaded Successfully</p>
                  </div>
                ) : (
                  <div className="resume-wrap">
                    <p className="file-upload"> Upload Your Resume</p>

                    <img src={ResumeIcon} />
                  </div>
                )}
              </label>
            </div>
            <div className="span-content">
              <span className={errors.inValidPdf ? "errorMsg" : "hideMsg"}>
                Invalid Format
              </span>
            </div>
            <div className="span-content">
              <span className={errors.isLargeFile ? "errorMsg" : "hideMsg"}>
                File size should not exceed 2 MB
              </span>
            </div>

            <div className="btn-wrap">
              <button onClick={() => this.handleModal()}>Cancel</button>
              <button onClick={() => this.handleSubmit()}>Submit</button>
            </div>
          </div>
        </Modal>
        <Footer props={this.props} />
      </>
    );
  }
}
export default Careers;
