import "./stylesheets/style.css";
import MainRouter from "./routes";
import AOS from "aos";
import "aos/dist/aos.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
AOS.init();
function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Le6eK4fAAAAACucCRJc1k-W2QOdoQX0x0cVB9tB">
      {" "}
      <MainRouter />{" "}
    </GoogleReCaptchaProvider>
  );
}

export default App;
