import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import HowWeDo from "./pages/HowWeDo";
import Solutions from "./pages/Solutions";
import Contact from "./pages/Contact";
import Careers from "./pages/Careers";
import About from "./pages/About";
import TermsAndConditions from "./pages/terms_conditions";
import Privacy from "./pages/privacy";
class MainRouter extends Component {
  render = () => {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          {/* <Route path="/home" exact component={Home} /> */}
          <Route path="/how_we_do" exact component={HowWeDo} />
          <Route path="/solutions" exact component={Solutions} />
          <Route path="/contact_us" exact component={Contact} />
          <Route path="/careers" exact component={Careers} />
          <Route path="/about" exact component={About} />
          <Route
            path="/terms_conditions"
            exact
            component={TermsAndConditions}
          />
          <Route path="/privacy_policy" exact component={Privacy} />
        </Switch>
      </Router>
    );
  };
}

export default MainRouter;
