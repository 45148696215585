import React, { Component } from "react";
import Footer from "../layouts/footer";
import Navbar from "../layouts/Navbar";
export default class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {" "}
        <Navbar props={this.props} />
        <div className="privacy_container">
          <h2>INCUBATOR PRIVACY POLICIES</h2>
          <p>
            {" "}
            At Incubator, one of our main priorities is the privacy of our
            visitors. This Privacy Policy document contains types of information
            that is collected and recorded by Incubator and how we use it. If
            you have additional questions or require more information about our
            Privacy Policy, do not hesitate to contact us through email at
            Incubator. An example of a privacy policy can be found at
            buyproxies.io.
          </p>
          <h2>LOG FILES</h2>
          <p>
            {" "}
            Incubator follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services’ analytics. The information
            collected by log files include:
          </p>
          <ul>
            <li>internet protocol (IP) addresses</li>
            <li>browser type, Internet Service Provider (ISP)</li>
            <li>date and time stamp, referring/exit pages</li>
            <li>possibly the number of clicks</li>
          </ul>
          <p>
            {" "}
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users’ movement on the
            website, and gathering demographic information.
          </p>
          <h2>PRIVACY POLICIES</h2>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Incubator. Third-party ad servers or ad
            networks uses technologies like cookies, JavaScript, or Web Beacons
            that are used in their respective advertisements and links that
            appear on Incubator, which are sent directly to users’ browser. They
            automatically receive your IP address when this occurs. These
            technologies are used to measure the effectiveness of their
            advertising campaigns and/or to personalize the advertising content
            that you see on websites that you visit. Note that Incubator has no
            access to or control over these cookies that are used by third-party
            advertisers.
          </p>
          <h2>THIRD PARTY POLICIES</h2>
          <p>
            Incubator’s Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options. You may find a complete list of
            these Privacy Policies and their links here: Privacy Policy Links.
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers’
            respective websites. What Are Cookies?
          </p>
          <h2>CHILDREN INFORMATION</h2>
          <p>
            {" "}
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
            Incubator does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
          <h2>ONLINE PRIVACY</h2>
          <p>
            This privacy policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Incubator. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>
          <h2>CONSENT</h2>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its Terms and Conditions. This privacy policy was created
            at privacypolicygenerator.info.
          </p>
          <h2>REFUND AND CANCELLATION POLICY</h2>
          <p>
            Our focus is on complete customer satisfaction. In the event, if you
            are displeased with the services provided, we will refund back the
            money, provided the reasons are genuine and proved after
            investigation. Please read the fine prints of each deal before
            buying it, it provides all the details about the services or the
            product you purchase. In case of dissatisfaction from our services,
            clients have the liberty to cancel their projects and request a
            refund from us. Our Policy for the cancellation and refund will be
            as follows:
          </p>
          <h2>CANCELLATION POLICY</h2>
          <p>
            A Customer may submit a claim for a refund for a purchase made by
            emailing at info@quadkast.com and providing a clear and specific
            reason for the refund request and the exact terms that have been
            violated. Requests received later than 10 business days before the
            end of the current service period will be treated as a cancellation
            of services for the next service period.
          </p>
          <h2>REFUND POLICY</h2>
          <p>
            {" "}
            We will try our best to create suitable design concepts for our
            clients. In case any client is not completely satisfied with our
            products we can provide a refund. If paid by credit card, refunds
            will be issued to the original credit card provided at the time of
            purchase and in case of payment gateway name payments refund will be
            made to the same account.
          </p>
          <h2>UPDATE</h2>
          <p>
            This Privacy Policy was last updated on March 2, 2020. If there will
            be any update, amendment, or changes to our Privacy Policy then
            these will be posted on this page
          </p>
        </div>
        <Footer props={this.props} />
      </>
    );
  }
}
