import React, { Component } from "react";
// import { CSpinner } from "@coreui/react";
import ReactS3Uploader from "react-s3-uploader";

//Constants
import constants from "../constants/Constants";

class ImageUploader extends Component {
  render() {
    const {
      label,
      name,
      imageName,
      loader,
      path,
      value,
      isLoading,
      start,
      disabled,
      error,
      finish,
      style,
      currentFileName,
      remove,
    } = this.props;

    return (
      <div className="flex-col mr-child-20">
        <div className="sm__upload">
          {isLoading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            ></div>
          )}
          {!isLoading && value !== "" && (
            <div className={"sm__upload--img"}>{currentFileName}</div>
          )}

          {!isLoading && !disabled && value === "" && (
            <ReactS3Uploader
              signingUrl={constants.S3_UPLOADER.SIGNIN_URL}
              signingUrlMethod="GET"
              accept="application/pdf"
              // s3path={path}
              preprocess={(file, next) => start(file, next, name, loader)}
              onError={(message) => error(message, name, loader)}
              onFinish={(url) => finish(url, name, loader)}
              uploadRequestHeaders={{
                "x-amz-acl": "public-read",
              }}
              contentDisposition="auto"
              scrubFilename={(filename) => {
                let ext = filename.split(".")[filename.split(".").length - 1];
                return `${imageName.replace(/[^a-zA-Z0-9_]/g, "")}.${ext}`;
              }}
              className="sm__hide-s3 sm__s3"
            />
          )}
        </div>
      </div>
    );
  }
}

export default ImageUploader;
