import React, { Component } from "react";
import Footer from "../layouts/footer";
import Navbar from "../layouts/Navbar";
export default class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {" "}
        <Navbar props={this.props} />
        <div className="privacy_container">
          <h2>Privacy Statement for QuadKast</h2>
          <p>
            QuadKast has created this privacy statement in order to demonstrate
            our company's commitment to privacy. The following discloses our
            information gathering and dissemination practices for this website:
            easyprojects.net
          </p>
          <h2>Collection of Personal Information from Service Visitors.</h2>
          <p>
            QuadKast may collect and/or track (1) the home server domain names,
            email addresses, type of client computer, files downloaded, search
            engine used, operating system, and type of web browser of visitors
            to Quadkast web service, (2) the email addresses of visitors that
            communicate with Quadkast via email, (3) information knowingly
            provided by the visitor in online forms, registration forms,
            surveys, email, contest entries, and other online avenues (including
            demographic and personal profile data), and (4) aggregate and
            user-specific information on which pages visitors access. QuadKast
            may place Internet “cookies” on visitors’ hard drives. Internet
            cookies save data about individual visitors, such as the
            organization’s name, password, user-name, screen preferences, and
            the pages of a service viewed by the visitor. When the visitor
            revisits QuadKast web service, QuadKast may recognize the visitor by
            the Internet cookie and customize the visitor’s experience
            accordingly. Visitors may decline Internet cookies, if any, by using
            the appropriate feature of their web client software, if available.
          </p>
          <h2>Use of Personal Data Collected.</h2>
          <p>
            Personal data collected by QuadKast may be used by QuadKast for
            editorial and feedback purposes, for marketing and promotional
            purposes, for a statistical analysis of users’ behavior, for product
            development, for content improvement, or to customize the content
            and layout of QuadKast service. Aggregate data on visitors’ home
            servers may be used for internal purposes but will not be provided
            to third parties such as marketing firms. Individually identifying
            information, such as names, postal and email addresses, phone
            numbers, and other personal information which visitors voluntarily
            provide to QuadKast may be added to QuadKast databases and used for
            future calls and mailings regarding service updates, new products
            and services, and upcoming events.
          </p>
          <span>
            We keep customers contact information strictly confidential and use
            it only to send important information to our customers.
          </span>
          <h2>Security Measures.</h2>
          <p>
            {" "}
            QuadKast has implemented numerous security features to prevent the
            unauthorized release of or access to personal information. For
            example, all QuadKast employees are required to certify their
            understanding that personal information is considered confidential,
            that it is important to safeguard personal information, and that
            QuadKast will take appropriate action against any employee who fails
            to acknowledge these facts or adhere to the requisite standards of
            conduct. Please be advised, that QuadKast is not responsible for the
            security of information transmitted via the Internet (electronic
            mail services). For more private communication contact QuadKast by
          </p>
          <h2>QuadKast Right to Contact Users.</h2>
          <p>
            {" "}
            QuadKast reserves the right to contact service visitors regarding
            sales and other informational requests made through its web service.
          </p>
          <h2>QuadKast Right to Change Privacy Policy.</h2>
          <p>
            {" "}
            QuadKast reserves the right to change this Privacy Policy at any
            time without notice. Any change to this Privacy Policy shall be
            effective as to any visitor that has accepted the easyprojects.net
            Service Terms and Conditions before the change was made.
          </p>
          <h2>Contact Information</h2>
          <p>
            QuadKast welcomes your comments regarding this Statement of Privacy,
            please contact us by e-mail, or postal mail.
          </p>
          <p>
            QuadKast Plot 12c/1, 3rd Cross St, South Phase, Guindy Industrial
            Estate, Guindy, Chennai, India 600032.
          </p>
          <p> Mail : info@quadkast.com</p>
        </div>
        <Footer props={this.props} />
      </>
    );
  }
}
