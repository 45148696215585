import React, { Component } from "react";
import Logo from "../assets/logo-black.svg";
import Bird from "../assets/icons/bird.svg";
import FaceBook from "../assets/icons/fb.svg";
import Tube from "../assets/icons/tube.svg";
import Insta from "../assets/icons/insta.svg";
import moment from "moment";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { props } = this.props;
    return (
      <>
        {" "}
        <div className="footer_text">
          <div className="footer_inherit">
            {" "}
            <img
              src={Logo}
              alt="Logo"
              onClick={() => props.history.push({ pathname: "/" })}
              style={{ cursor: "pointer" }}
            />
            <ul className="ul-list">
              <li
                onClick={() =>
                  props.history.push({
                    pathname: "/how_we_do",
                  })
                }
              >
                How we do
              </li>
              <li
                onClick={() =>
                  props.history.push({
                    pathname: "/solutions",
                  })
                }
              >
                Solutions
              </li>
              <li
                onClick={() =>
                  props.history.push({
                    pathname: "/about",
                  })
                }
              >
                About Us
              </li>
              <li
                onClick={() =>
                  props.history.push({
                    pathname: "/contact_us",
                  })
                }
              >
                Contact Us
              </li>
              <li
                onClick={() =>
                  props.history.push({
                    pathname: "/careers",
                  })
                }
              >
                Careers
              </li>
            </ul>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="footer_inherit">
            <p className="quad-rights">
              © {moment().year()} Quadkast Technologies Pvt Ltd. All rights
              reserved.
            </p>
            <p className="terms-conditions cursor_pointer">
              <span
                onClick={() =>
                  props.history.push({
                    pathname: "/terms_conditions",
                  })
                }
              >
                Terms and Conditions
              </span>{" "}
              |{" "}
              <span
                onClick={() =>
                  props.history.push({
                    pathname: "/privacy_policy",
                  })
                }
              >
                Privacy Policy
              </span>{" "}
            </p>
            <div className="footer_img">
              <a href="http://www.twitter.com/quadkast" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
              <a href="http://www.facebook.com/quadkast" target="_blank">
                <i className="fa fa-facebook-f"></i>
              </a>
              <a
                href="http://www.linkedin.com/company/quadkast-technologies"
                target="_blank"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Footer;
