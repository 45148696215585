import React, { Component } from "react";
import Navbar from "../layouts/Navbar";
import Think from "../assets/solutions/think.svg";
import Ellipse from "../assets/solutions/ellipse.svg";
import Teliver from "../assets/solutions/teliverGroup.svg";
import Tele from "../assets/solutions/tele.svg";
import Virus from "../assets/solutions/virus.svg";
import Bag from "../assets/solutions/bag.svg";
import Footer from "../layouts/footer";
import scrollToTop from "../utils/utils";
import AOS from "aos";
import QKart from "../../src/assets/Qkart.png";
import "aos/dist/aos.css";
class Solutions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    scrollToTop();
    AOS.init({
      disable: function () {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
        // return true;
      },
    });
  }
  render() {
    return (
      <>
        <Navbar props={this.props} />
        <div className="Solutions__banner">
          <div className="banner-content">
            <p
              className="remedy"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              A Simple Tool That Help <span> Grow Your Business</span>{" "}
            </p>
            <p
              className="remedy_content"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
              data-aos-delay="600"
            >
              Everything has an solution but what needed is the exact solution
              and we are an experienced, forward-thinking team of software
              engineers shaping a new reality with exciting ideas and
              cutting-edge technology. Join us and get new opportunities for
              career growth.
            </p>
            {/* <div
              className="call-trigger"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
              data-aos-delay="700"
            >
              <p>Get Started</p>
            </div> */}
          </div>
          <div>
            <img
              src={Think}
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            />
          </div>
        </div>
        <div
          className="Solutions__track"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-duration="1000"
        >
          <img src={Ellipse} />
          <p>
            Real-time tracking solutions <span>'T'ELIVERED</span>
          </p>
          <p>
            A coded marval in the form of an SDK for business and personal apps
            to slot in live Tracking!{" "}
          </p>
          <p>
            A simple embed and your App is all set to revolutionize a greater
            experience
          </p>
        </div>
        <div className="Solutions__teliver">
          <div>
            <img
              src={Teliver}
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="100"
            />
          </div>
          <div className="teliver-content">
            <div
              className="flex"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div>
                {" "}
                <img className="tele" src={Tele} />{" "}
              </div>
              <div className="tele-content">
                <h3>
                  Real Time <span>Tracking</span>{" "}
                </h3>
                <p>
                  Our protocol helps us to track the exact location with great
                  speed and accuracy. And our next goal is to achieve working
                  even with low networks and bandwidths.
                </p>
              </div>
            </div>
            <div
              className="flex"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div>
                {" "}
                <img className="tele" src={Bag} />{" "}
              </div>
              <div className="tele-content">
                <h3>
                  Safe and
                  <span> Secure</span>{" "}
                </h3>
                <p>
                  Our real-time tracking protocal is designed in a way that
                  provides a safe and secured platform.Your details are Ours and
                  they are secured
                </p>
              </div>
            </div>
            <div
              className="flex"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <div>
                {" "}
                <img className="tele" src={Virus} />{" "}
              </div>
              <div className="tele-content">
                <h3>
                  Highly
                  <span> Customizable</span>{" "}
                </h3>
                <p>
                  We, Quadkast are working with great scope and futuristic ideas
                  of developing the project making it more a userfriendly SDK.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="Solutions__track"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-duration="1000"
        >
          <img src={Ellipse} />
          <p>
            E-Commerce Solution <span>Qkart</span>
          </p>
          <p>
            Working to earn and keep our customers’ trust is the single biggest
            driver of Qkart's approach.
          </p>
          <p>
            The vision of project is to become an most customer-centric product.
          </p>
        </div>
        <div
          className="Solutions__teliver"
          style={{ flexFlow: "row-reverse", marginBottom: "3rem" }}
        >
          <div className="qkart" style={{ width: "44%" }}>
            <img
              src={QKart}
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="100"
            />
          </div>
          <div className="teliver-content">
            <div
              className="flex"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div>
                {" "}
                <img className="tele" src={Tele} />{" "}
              </div>
              <div className="tele-content">
                <h3>
                  Qkart <span></span>{" "}
                </h3>
                <p>
                  Qkart is developed to engage and develop your e-commerce
                  ideas. We provides you the opportunity to purchase our product
                  and implement whats on your mind.
                </p>
              </div>
            </div>
            <div
              className="flex"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div>
                {" "}
                <img className="tele" src={Bag} />{" "}
              </div>
              <div className="tele-content">
                <h3>
                  Products and Qualities
                  <span> </span>{" "}
                </h3>
                <p>
                  {" "}
                  Qkart product is built as a solution to the new innovations of
                  e-commerce with greater, faster and more reliable resource for
                  your new creations.
                </p>
              </div>
            </div>
            <div
              className="flex"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <div>
                {" "}
                <img className="tele" src={Virus} />{" "}
              </div>
              <div className="tele-content">
                <h3>
                  Highly
                  <span> Customizable</span>{" "}
                </h3>
                <p>
                  Qkart adapts to any e-commerce product you want.This SDK is
                  easily customizable, making your e-commerce website more
                  attractive to the users.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer props={this.props} />
      </>
    );
  }
}
export default Solutions;
