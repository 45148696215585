import React, { Component } from "react";
import Navbar from "../layouts/Navbar";
import Call from "../assets/contact/call.svg";
import Location from "../assets/contact/location.svg";
import Mess from "../assets/contact/mess.svg";
import Footer from "../layouts/footer";
import ContactImg from "../assets/contact/contact.svg";
import scrollToTop from "../utils/utils";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import ApiCall from "../constants/ApiCall";
let token = "";
class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      successMessage: false,
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleRecaptchaVerify(token) {
    this.setState({ recaptchaToken: token });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      alert("Please verify that you are not a robot.");
      return;
    }

    // Proceed with form submission

    let { name, email, subject, message } = this.state;
    e.preventDefault();

    let data = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    ApiCall.post("auth/requests", data, (res) => {
      if (res.success) {
        this.setState({ successMessage: true });
        setTimeout(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        }, 2000);
      }
    });
  };
  verifyCallback = (recaptchaToken) => {
    this.setState({ recaptchaToken: recaptchaToken });
  };

  render() {
    return (
      <>
        <Navbar props={this.props} />
        <div className="Contact__banner">
          <div
            className="banner-content"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <p className="remedy">
              Contact <span> Us</span>{" "}
            </p>
            <p className="remedy_content">
              We would love to hear from you! Our team is accessible across the
              Globe. Fill in your queries, our team will get in touch with you
              shortly.
            </p>
            {/* <div className="call-trigger">
              <p>Get Started</p>
            </div> */}
          </div>
          <div>
            <img
              src={ContactImg}
              data-aos="zoom-out"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <div
          className="Contact__touch"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h2>
            Get In <p> Touch</p>
          </h2>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d971.8422992295084!2d80.20854052915833!3d13.012145306095425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267ee485db699%3A0xba1f7ae55a120e2a!2sQuadkast%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1677568686262!5m2!1sen!2sin"
          height="350"
          style={{
            border: "0",
            width: "100%",
          }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
        <section id="contact" className="Contact__contact">
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <div>
                      {" "}
                      <img src={Location} />
                    </div>

                    <div className="location_div">
                      <p>Location</p>
                      <p>
                        B20, 3rd Floor, SKCL Excellence, <br></br>
                        Guindy Industrial Estate, <br />
                        Chennai, Tamil Nadu 600032.
                      </p>
                    </div>
                  </div>

                  <div className="address">
                    <div>
                      {" "}
                      <img src={Mess} />
                    </div>

                    <div className="location_div">
                      {" "}
                      <p>Email</p>
                      <p>info@quadkast.com</p>
                    </div>
                  </div>

                  {/* <div className="address">
                    <div>
                      {" "}
                      <img src={Call} />{" "}
                    </div>
                    <div className="location_div">
                      {" "}
                      <p>Call:</p>
                      <p>+1 5589 55488 55s</p>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">
                <form
                  // action="forms/contact.php"
                  // method="post"
                  // role="form"
                  onSubmit={(e) => this.handleSubmit(e)}
                  className="php-email-form"
                >
                  <div className="row_input">
                    <div className="input-wrap">
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Name"
                          required
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Email"
                          required
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group inherit mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group inherit mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                  {/* <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div> */}
                  <div className="text-center">
                    <button type="submit">
                      {this.state.successMessage
                        ? "Request Sent Successfully"
                        : " Send Message"}
                    </button>
                  </div>
                  <GoogleReCaptcha
                    action="submitForm"
                    onVerify={(e) => {
                      token = e;
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer props={this.props} />
      </>
    );
  }
}
export default Contact;
