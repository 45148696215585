import React, { Component } from "react";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/footer";
import Silde_one from "../assets/about/slide1.jpg";
import Silde_two from "../assets/about/slide2.jpg";
import Silde_three from "../assets/about/slide3.jpg";
import scrollToTop from "../utils/utils";
import Timeline from "@mui/lab/Timeline";
import AboutBanner from "../../src/assets/newBanner.jpg";
import newBanner from "../../src/assets/newBanner.jpg";
import Events from "../../src/assets/events.png";
import TeamPhoto from "../../src/assets/team.png";
import StarHeading from "../../src/assets/about_star.svg";
import Year from "../../src/assets/year.svg";
import Journery from "../../src/assets/journey.png";
import CurrentYear from "../../src/assets/currentYear.png";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import CarouselSlider from "react-carousel-slider";
import PresentYear from "../../src/assets/currentYear.svg";
// import Slider from "react-carousel-responsive";
// import "react-carousel-responsive/dist/styles.css";
class About extends Component {
  constructor(props) {
    super(props);
    // this.offerRef = React.createRef();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentDidMount() {
    scrollToTop();
  }
  render() {
    let data = [
      {
        des: "1",
        imgSrc: Silde_one,
      },
      {
        des: "2",
        imgSrc: Silde_two,
      },
      {
        des: "3",
        imgSrc: Silde_three,
      },
    ];
    return (
      <>
        <Navbar props={this.props} />
        <div className="whole_container">
          <div className="about_container d_flex_align_justify">
            <div className="initial_about_content">
              <h2 className="ft_600">About Quadkast</h2>
              <p className="ft_18 ft_400">
                Quadkast is initiated by Experienced and Passionate Engineers,
                in a noteworthy approach to provide encouraging solution, after
                gaining a vast experience in SMAC. To go beyond the boundaries
                we increase our velocity hard and migrate into Digital. In
                Quadkast we respect individual ideas and transform it into
                action by forcible teamwork.
              </p>
            </div>
          </div>
          <div className="image_container">
            <img src={newBanner} />
          </div>
          <div className="journery_container">
            <h2 className="content_heading ft_700">
              Our <span>Journey</span>
            </h2>
            <div className="flex_first_container d_flex_align">
              <div className="left_side_start">
                <p className="start_text">Starts on</p>
                <div className="start_year">
                  <img src={Year} />
                </div>
              </div>
              <div className="right_side_start">
                <img src={Journery} />
              </div>
            </div>
            {this.state.width > 480 ? (
              <>
                <div className="journey_flow_container">
                  <div className="d_flex" style={{ height: "fit-content" }}>
                    <div
                      className="history_design_left "
                      style={{ paddingTop: "0px" }}
                    >
                      <div className="year_holder ft_600 s">2016</div>
                      <div className="date_holder">
                        Sep
                        <h4 className="ft_600"> 05</h4>
                      </div>
                      <h2 className="mt_0">Founded</h2>
                      <p>
                        Quadkast Started its Journey with 4 Experienced and
                        Passionated employees, in a noteworthy approach to
                        provide encouraging solution, after gaining a vast
                        experience in SMAC, Quadkast was incorporated.
                      </p>
                    </div>
                    <div className="w_50"></div>
                  </div>
                  <div className="d_flex">
                    <div className="history_design_joiner "></div>
                    <div className="w_50 history_right_side_container second_stage">
                      <div className="year_holder ft_600 s">2016</div>
                      <div className="date_holder">
                        Dec
                        <h4 className="ft_600"> 12</h4>
                      </div>
                      <h2>Our initial projects</h2>
                      <p>
                        We succesfully completed about 5+ fresh projects with
                        quadkast’s flavor of perfection & standards and we
                        marched towards the plan of developing our own Mobile
                        Application.
                      </p>
                    </div>
                  </div>
                  <div className="d_flex">
                    <div className="history_design_left third_stage center_align_stage">
                      <div className="year_holder ft_600 s">2017</div>
                      <div className="date_holder">
                        Apr
                        <h4 className="ft_600"> 10</h4>
                      </div>
                      <h2>A Quadkast’s product</h2>
                      <p>
                        In April, Real Time Tracking Solution <b>Teliver</b> was
                        <b>‘T’ELIVERED</b> to the market, a Quadkast’s product.
                        A coded marval in the form of an SDK for business and
                        personal apps to slot in live Tracking! .
                      </p>
                    </div>
                    <div className="w_50"></div>
                  </div>
                  <div className="d_flex">
                    <div className="history_design_joiner "></div>
                    <div className="w_50 history_right_side_container forth_stage">
                      <div className="year_holder ft_600 s">2017</div>
                      <div className="date_holder">
                        Sep
                        <h4 className="ft_600"> 10</h4>
                      </div>
                      <h2>Teliver</h2>
                      <p>
                        Our simple embedded app crossed its 100 Active Customer
                        Mark in just 5 Months. With all safe, secure and
                        customizable features our app is all set to make
                        revolutionary changes and was reaching further to a
                        greater audience.
                      </p>
                    </div>
                  </div>
                  <div className="d_flex">
                    <div className="history_design_left fifth_stage center_align_stage">
                      <div className="year_holder ft_600 s">2018</div>
                      <div className="date_holder">
                        Jan
                        <h4 className="ft_600"> 07</h4>
                      </div>
                      <h2>15+ Projects done</h2>
                      <p>
                        We achieved a landmark of completing 15+ Mobile Apps &
                        Web Applications. Our company was in an successful and
                        tremendous flow, and with all the hopes we were all set
                        for a greater expansion.
                      </p>
                    </div>
                    <div className="w_50"></div>
                  </div>
                  <div className="d_flex">
                    <div className="history_design_joiner "></div>
                    <div className="w_50 history_right_side_container second_stage">
                      <div className="year_holder ft_600 s">2019</div>
                      <div className="date_holder">
                        Jan
                        <h4 className="ft_600"> 29</h4>
                      </div>
                      <h2>A new environment </h2>
                      <p>
                        New Projects, New Environment and New Energetic
                        employees, Increasing the size of the Team we moved to a
                        commercial place.
                      </p>
                    </div>
                  </div>
                  <div className="d_flex">
                    <div className="history_design_left third_stage center_align_stage">
                      <div className="year_holder ft_600 s">2020</div>
                      <div className="date_holder">
                        Nov
                        <h4 className="ft_600"> 12</h4>
                      </div>
                      <h2>20+ Project</h2>
                      <p>
                        Even covid couldn’t stop us and our projects. We
                        Successfully completed about 20+ Mobile Apps & Web
                        Applications. We were happy with so many upcoming
                        projects for the quality and standards Quadkast
                        delivered.
                      </p>
                    </div>
                    <div className="w_50"></div>
                  </div>
                  <div className="d_flex">
                    <div className="history_design_joiner "></div>
                    <div className="w_50 history_right_side_container second_stage">
                      <div className="year_holder ft_600 s">2022</div>
                      <div className="date_holder">
                        Jan
                        <h4 className="ft_600"> 05</h4>
                      </div>
                      <h2>A new environment </h2>
                      <p>
                        Celebrating 6 Successful Years of Quadkast, Our Team
                        size has now been increased to 30 dynamic Employees with
                        . With Persistent Vision and Hardwork quadkast is
                        entering its 7th year and yet more to come.
                      </p>
                    </div>
                  </div>
                  {/* <div className="d_flex" style={{ height: "fit-content" }}>
                    <div className="d_flex_align_justify current_year">
                      <img src={CurrentYear} />
                    </div>
                    <div className="history_right_side_container second_stage ">
                      <div className="year_holder last_year ft_600 s"></div>
                      <div className="date_holder">
                        <h4 className="ft_600"> </h4>
                      </div>
                      <h2>Now </h2>
                      <p></p>
                      <div className="presentYear">
                        <img src={PresentYear} />
                      </div>
                    </div>
                  </div> */}
                  <div className="d_flex ">
                    <div className="history_design_left third_stage center_align_stage  current_year_style">
                      <div className="year_holder last_year ft_600 ">2023</div>
                      <div className="date_holder">
                        Feb
                        <h4 className="ft_600"> 27</h4>
                      </div>
                      <h2>Our New Location</h2>
                      <p>
                        Quadkast changed its workspace to a new and fascinating
                        place where employees can work with great interest and
                        calmness.
                      </p>
                      <div className="presentYear mb_30">
                        <img src={PresentYear} />
                      </div>
                    </div>
                    <div className="d_flex_align_justify current_year ">
                      <img src={CurrentYear} />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="responsive_history_design">
                <div
                  className="history_design_left res_design"
                  style={{ paddingTop: "0px" }}
                >
                  <div className="year_holder ft_600 s">2016</div>
                  <div className="date_holder">
                    Sep
                    <h4 className="ft_600"> 05</h4>
                  </div>
                  <div className="content_history">
                    <h2>Founded</h2>
                    <p>
                      Quadkast Started its Journey with 4 Experienced and
                      Passionated employees, in a noteworthy approach to provide
                      encouraging solution, after gaining a vast experience in
                      SMAC, Quadkast was incorporated.
                    </p>
                  </div>
                </div>
                <div className=" history_right_side_container second_stage res_next_levels">
                  <div className="year_holder ft_600 s">2016</div>
                  <div className="date_holder">
                    Dec
                    <h4 className="ft_600"> 12</h4>
                  </div>
                  <div className="content_history">
                    {" "}
                    <h2>Our initial projects</h2>
                    <p>
                      We succesfully completed about 5+ fresh projects with
                      quadkast’s flavor of perfection & standards and we marched
                      towards the plan of developing our own Mobile Application.
                    </p>
                  </div>
                </div>
                <div className="history_right_side_container third_stage center_align_stage res_next_levels">
                  <div className="year_holder ft_600 s">2017</div>
                  <div className="date_holder">
                    Apr
                    <h4 className="ft_600"> 10</h4>
                  </div>
                  <div className="content_history">
                    <h2>A Quadkast’s product</h2>
                    <p>
                      In April, Real Time Tracking Solution <b>Teliver</b> was
                      <b>‘T’ELIVERED</b> to the market, a Quadkast’s product. A
                      coded marval in the form of an SDK for business and
                      personal apps to slot in live Tracking! .
                    </p>
                  </div>
                </div>
                <div className=" history_right_side_container forth_stage center_align_stage res_next_levels">
                  <div className="year_holder ft_600 s">2017</div>
                  <div className="date_holder">
                    Sep
                    <h4 className="ft_600"> 10</h4>
                  </div>
                  <div className="content_history">
                    <h2>Teliver</h2>
                    <p>
                      Our simple embedded app crossed its 100 Active Customer
                      Mark in just 5 Months. With all safe, secure and
                      customizable features our app is all set to make
                      revolutionary changes and was reaching further to a
                      greater audience.
                    </p>
                  </div>
                </div>
                <div className="history_right_side_container fifth_stage center_align_stage res_next_levels">
                  <div className="year_holder ft_600 s">2018</div>
                  <div className="date_holder">
                    Jan
                    <h4 className="ft_600"> 07</h4>
                  </div>
                  <h2>15+ Projects done</h2>
                  <p>
                    We achieved a landmark of completing 15+ Mobile Apps & Web
                    Applications. Our company was in an successful and
                    tremendous flow, and with all the hopes we were all set for
                    a greater expansion.
                  </p>
                </div>
                <div className=" history_right_side_container second_stage res_next_levels">
                  <div className="year_holder ft_600 s">2019</div>
                  <div className="date_holder">
                    Jan
                    <h4 className="ft_600"> 29</h4>
                  </div>
                  <h2>A new environment </h2>
                  <p>
                    New Projects, New Environment and New Energetic employees,
                    Increasing the size of the Team we moved to a commercial
                    place.
                  </p>
                </div>
                <div className="history_right_side_container third_stage center_align_stage res_next_levels">
                  <div className="year_holder ft_600 s">2020</div>
                  <div className="date_holder">
                    Nov
                    <h4 className="ft_600"> 12</h4>
                  </div>
                  <h2>20+ Project</h2>
                  <p>
                    Even covid couldn’t stop us and our projects. We
                    Successfully completed about 20+ Mobile Apps & Web
                    Applications. We were happy with so many upcoming projects
                    for the quality and standards Quadkast delivered.
                  </p>
                </div>
                <div className="history_right_side_container current_stage center_align_stage res_next_levels">
                  <div className="year_holder ft_600 s">2022</div>
                  <div className="date_holder">
                    Mar
                    <h4 className="ft_600"> 03</h4>
                  </div>
                  <h2 style={{ paddingTop: "76px" }}>A Big Step</h2>
                  <p>
                    Celebrating 6 Successful Years of Quadkast, Our Team size
                    has now been increased to 30 dynamic Employees with 40+
                    projects Completed. With Persistent Vision and Hardwork
                    quadkast is entering its 7th year and yet more to come.
                  </p>
                </div>
                <div className="history_right_side_container third_stage center_align_stage res_next_levels">
                  <div className="year_holder ft_600 s">2023</div>
                  <div className="date_holder">
                    Feb
                    <h4 className="ft_600"> 27</h4>
                  </div>
                  <h2>Now</h2>
                  <p>
                    Quadkast changed its workspace to a new and fascinating
                    place where employees can work with great interest and
                    calmness.
                  </p>
                </div>
                <div className="presentYear mt_30">
                  <img src={PresentYear} />
                </div>
              </div>
            )}
          </div>
          <div className="our_values mt_20">
            <div className="heading_div">
              <h2 className="content_heading ft_700">
                Our <span>Values</span>
                <img className="first_star" src={StarHeading} />
                <img className="second_star" src={StarHeading} />
              </h2>
            </div>
            <div className="our_values_content">
              <div className="w_50">
                <img src={Events} />
              </div>
              <div className="values_data">
                <h3 className="ft_600">Working hard while having fun</h3>
                <p>
                  As you all know, creating a company is a long road that takes
                  passion and hard work. That’s why it’s so important to enjoy
                  the small things and have fun along the way.
                </p>
              </div>
            </div>
          </div>
          <div className="our_team">
            <h2 className="content_heading ft_700">
              Meet the <span>Team</span>
            </h2>
            <div className="team_image">
              <img src={TeamPhoto} />
            </div>
          </div>
        </div>
        <Footer props={this.props} />
      </>
    );
  }
}
export default About;
