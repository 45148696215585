import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
export default function scrollToTop() {
  return window.scrollTo(
    {
      top: 0,
      behavior: "smooth",
    },
    () => AOS.init()
  );
}
