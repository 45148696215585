export const isInvalidName = (value) => {
  let nameRegex = /^[a-zA-Z ]+$/;
  if (!nameRegex.test(value)) {
    return true;
  } else {
    return false;
  }
};
export const isInvalidEmail = (email) => {
  let eRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!eRegex.test(email)) return true;
  else return false;
};
export const GET_AWS_LINK = (result) => {
  return "https://qkapps.s3.amazonaws.com/" + result.filename;
};
export const isInvalidImage = (file) => {
  let imageType = "application/pdf";
  let result = false;
  if (!file.type.match(imageType)) {
    result = true;
  }
  return result;
};

export const isLargeFile = (file) => {
  return file.size / 1024 / 1024 > 2;
};
