import React, { Component } from "react";
import Logo from "../assets/logo.svg";
import { NavLink } from "react-router-dom";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      showSideBar: false,
      width: 0,
    };
    this.handleSroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    this.setState({
      width: window.screen.width,
    });
  }
  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      this.setState({ scrolled: !this.state.scrolled });
    }
  };
  toggleSideBar = (side) => {
    if (side === "left") {
      this.setState({
        showSideBar: true,
      });
    } else {
      this.setState({ showSideBar: false });
    }
  };
  render() {
    let { props } = this.props;
    return this.state.width > 480 ? (
      <div
        onScroll={this.handleScroll}
        className={this.state.scrolled ? "navbar_demo box" : "navbar_demo"}
      >
        <div className="navbar_inherit">
          {" "}
          <img
            src={Logo}
            alt="Logo"
            onClick={() => props.history.push({ pathname: "/" })}
          />
          <ul className="ul-list">
            <li>
              <a
                onClick={() =>
                  props.history.push({
                    pathname: "/how_we_do",
                  })
                }
              >
                <NavLink activeClassName="active" to="/how_we_do">
                  How we do
                </NavLink>
              </a>
            </li>
            <li>
              <a
                onClick={() =>
                  props.history.push({
                    pathname: "/solutions",
                  })
                }
              >
                <NavLink activeClassName="active" to="/solutions">
                  Solutions
                </NavLink>
              </a>{" "}
            </li>
            <li
              onClick={() =>
                props.history.push({
                  pathname: "/about",
                })
              }
            >
              <NavLink activeClassName="active" to="/about">
                About Us
              </NavLink>
            </li>
            <li
              onClick={() =>
                props.history.push({
                  pathname: "/contact_us",
                })
              }
            >
              <a>
                {" "}
                <NavLink activeClassName="active" to="/contact_us">
                  Contact Us{" "}
                </NavLink>
              </a>
            </li>
            <li
              onClick={() =>
                props.history.push({
                  pathname: "/careers",
                })
              }
            >
              <a>
                {" "}
                <NavLink activeClassName="active" to="/careers">
                  Careers
                </NavLink>
              </a>
            </li>
          </ul>
        </div>
      </div>
    ) : (
      <div className="responsive_nav_bar">
        <div
          className={
            this.state.showSideBar ? "sideBar_container" : "hide_contianer"
          }
        >
          <div className="left_side_bar">
            <div className="sidebar_logo">
              <img
                src={Logo}
                onClick={() => props.history.push({ pathname: "/" })}
              ></img>
            </div>
            <div className="sidebar_pages_link">
              <ul className="pages_ul">
                <li>
                  {" "}
                  <NavLink className="sidebar_link" to="/how_we_do">
                    How we do
                  </NavLink>
                </li>
                <li>
                  <NavLink className="sidebar_link" to="/solutions">
                    Solutions
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink className="sidebar_link" to="/about">
                    <a> About Us</a>
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink className="sidebar_link" to="/contact_us">
                    Contact Us{" "}
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink className="sidebar_link" to="/careers">
                    Careers
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="right_side_bar"
            onClick={() => this.toggleSideBar("right")}
          ></div>
        </div>
        <div className="res_nav_bar_content">
          <i
            className="fa fa-bars hamburgur"
            onClick={() => this.toggleSideBar("left")}
          ></i>
          <img
            src={Logo}
            alt="Logo"
            onClick={() => props.history.push({ pathname: "/" })}
          />
        </div>
      </div>
    );
  }
}
export default Navbar;
